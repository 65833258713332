<template>
  <!-- login-area -->
  <section class="login-area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <div class="login-left row">
            <div class="introduction">
              <div
                class="heading wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <h1>Welcome To DealersGear CRM</h1>
                <p>The Platform Built By Dealers For Dealers</p>
              </div>
              <div
                class="heading-img wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <img
                  class="img-fluid"
                  src="../assets/img/Frame.svg"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <div class="login-right row">
            <div class="login-form custom-form">
              <!-- logo -->
              <div
                class="logo-login wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <a href="index.html"
                  ><img
                    class="img-fulid"
                    src="../assets/img/logo.svg"
                    alt="logo"
                /></a>
              </div>
              <!-- logo -->
              <div
                class="login-title wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <p style="color: green">{{ message }}</p>
              </div>
              <form @submit.prevent="forgotpassword">
                <div
                  class="form-group label-design"
                  v-on:click="inputLabelStyle($event)"
                >
                  <label
                    for="exampleInputPassword2  label-top"
                    class="label-class"
                    >Password</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          src="assets/app/img/lock.524db919.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <input
                      id="exampleInputPassword2"
                      placeholder=""
                      @focus="inputLabelStyle($event, 'parent')"
                      type="password"
                      class="form-control"
                      v-model="password"
                    />
                  </div>
                </div>

                <div
                  class="form-group label-design"
                  v-on:click="inputLabelStyle($event)"
                >
                  <label for="exampleInputPassword1" class="label-class"
                    >Confirm Password</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          src="assets/app/img/lock.524db919.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <input
                      id="exampleInputPassword1"
                      placeholder=""
                      type="password"
                      class="form-control"
                      @focus="inputLabelStyle($event, 'parent')"
                      v-model="confirm_password"
                    />
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="login-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- login-area -->
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      email: this.$route.query.email,
      password: "",
      confirm_password: "",
      code: this.$route.query.code,

      message: "",
      allerros: [],
      success: false,
    };
  },
  methods: {
    forgotpassword() {
      axios
        .post("/reset/password", {
          password: this.password,
          confirm_password: this.confirm_password,
          code: this.code,
          email: this.email,
        })
        .then((response) => {
          this.allerros = [];
          this.success = true;
          //console.log(response.data.status);
          if (response.data.status == true) {
            this.message = response.data.message;
            window.location.href =
              "/login?message=Password changed successfully. Please login";
          }
          if (response.data.status == false) {
            this.message = response.data.message;
          }
        })
        .catch((error) => {
          this.allerros = error.response.data.errors;
        });
    },
    inputLabelStyle(event, type = null) {
      if (type == "parent") {
        $(event.currentTarget).parent().parent().addClass("input-border");
        $(
          event.currentTarget.parentElement.parentElement.querySelector("label")
        ).addClass("label-top");
      } else {
        $(event.currentTarget).addClass("input-border");
        $(event.currentTarget.querySelector("label")).addClass("label-top");
      }
    },
  },
};
</script>